import React from "react"
import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const GuitarElectronics = () => {

  return (
    <PageLayout>
      <SEO
        title="Guitar Electronics | Your Custom Build | King Electric Guitars"
        ogDescription="There is no right or wrong when it comes to guitar electronics. It's a matter of personal taste. I can help you track down the tone in your head. Let's build your dream guitar!"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Guitar Electronics</h1>
            <p>
              Pickups create a magnetic field. This allows the guitar strings to create a signal by shifting that magnetic field. Those shifts create changes in voltage. Everything that happens after the pickup will shape your overall guitar tone.
            </p>
            <p>
              The guitar electronics used on custom guitars are all over the map. A guitar's circuit includes:
            </p>
            <ul>
              <li>pickups</li>
              <li>potentiometers to adjust volume and tone</li>
              <li>capacitors to control the tone pots range</li>
              <li>resistors (when needed)</li>
              <li>switches to select between different pickups</li>
              <li>and an output jack</li>
            </ul>
            <p>
              We have tried and true guitar electronics circuits and pickup combinations. These have worked well for decades. But that doesn't mean that these traditional approaches are right for you. And, even within these traditional methods, we still have a lot of decisions to make.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Reach Out. Let's Talk!",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img src="/images/guitar-electronics.jpg" alt="Guitar Electronics" />
          </div>
        }
      />
      <div className="section textured">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/guitar-electronics-tone-shaping.jpg" alt="Guitar Electronics Tone Shaping" />
            </div>
          }
          textRight={
            <div>
              <h2>Tone Shaping With Guitar Electronics</h2>
              <p>
                There is no right or wrong when it comes to the electronic guitar parts you select. It's a matter of personal taste. You have to wire things correctly, of course. But, because guitar conventions use a certain value of tone pot, volume pot, and capacitor, doesn't mean that's the way it has to be. For instance, with a P-90 pickup most people will use 500K pots and an .047uF tone capacitor. But, what sounds best to you is what matters . It's not like I can't put a 250K pot in there with an .030uF tone capacitor. It'll give you a darker tone with a more suble tone control. And that might be what you're after.
              </p>
              <p>
                From my years of doing repairs, a lot of people replace a pickup because they feel it's missing something. They'll swap out $150 pickups searching for the right tone. But they rarely experiment with the guitar electronics in search of that tone. They could replace a $3 capacitor or $5 potentiometer and change the way a pickup sounds. And it makes all the difference in the world. The type of pickup you choose has a big effect on the guitar. But, the quality and values of the electronics used on those pickups matter too.
              </p>
              <p>
                I'm setting up a system where you can come in, sit down with your nearly finished custom guitar. And you can try out different values of volume pot, tone pot, and tone capacitor. I'm designing an electronic breadboard so we can switch between values. We'll clip into the guitar pickups. I'll select between 1M, 500k, and 250k tone posts and volume pots combined with 0.047uF, .030uF, and 0.022uF tone capacitors. As you play, you'll tell me what sounds right to you. Because I'm building this guitar for you. Not for me. What sounds best to you and me are going to be two different things. I want each of my customers to be completely satisfied with the guitar that I've built for them.
              </p>
              <p>
                Let's talk about the basic parameters with guitar electronics that we can adjust.
              </p>
            </div>
          }
        />
      </div>
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h3>Potentiometers and Resistance Values</h3>
            <p>
              Potentiometers are variable DC resistors used to control electric guitar volume or tone. Historically, we use 250k potentiometers with single-coil pickups. This is to dampen their inherent brightness. But, humbucker pickups are darker in tone. So, 500k or 1M potentiometers are often used to brighten their tone. In general, the higher the value of the potentiometer the brighter the guitar will be.
            </p>
            <h4>Pot Taper</h4>
            <p>
              The "taper" of a potentiometer refers to the relationship between the volume or tone setting and the amount of resistance applied. Guitars often use a logarithmic taper pot (also known as an audio taper pot) on the volume. This is because when turned to 5, the guitar will sound as though it is at half volume. The amount of resistance applied is actually only 15%-30% of the total pot value at that point. But, our ears will perceive that has half the volume level. As you turn the pot down more, the volume reduction will be more rapid. This is how guitars get that volume swell effect by moving the volume pot with their pinky while playing.
            </p>
            <p>
              I tend to use CTS pots for my guitars. They offer two types of logarithmic pots: a "modern taper "and, and a "true vintage taper". The true vintage will be a little quieter at half volume than the modern.
            </p>
            <p>
              For tone pots, you can use either a logarithmic or linear potentiometer. A linear pot will change the amount of resistance equally across the taper of the pot.  So, changes will have a less dramatic effect. But again, some players like to use the logarithmic/audio taper pot to do tonal sweeps like a wah pedal. In that case, we would use a logarithmic pot. It's all a matter of personal taste.
            </p>
            <p>
              There are also specialized low-friction volume and high friction tone pots. Eddie Van Halen designed a <a href="http://www.lonephantom.com/2011/03/evh-custom-low-friction-pot-review/" target="_blank" rel="noreferrer">low friction volume pot</a> for those fast swells he would do. The <a href="https://guitarelectronics.com/van-halen-evh-high-friction-guitar-pot-250k/" target="_blank" rel="noreferrer">high friction tone pot</a> allows for setting the tone without having to worry about bumping it.
            </p>
            <p>
              I'm happy to use whatever suits your playing style.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/guitar-electronics-potentiometers.jpg" alt="Guitar Electronics Volume and Tone Potentiometers" />
            <h4>Treble Bleed Circuit</h4>
            <p>
              With a typical passive volume pot, when you turn the pot down, a small amount of treble is also lost. How much treble the pot rolls off and above which frequency range is dependent upon two things. The resistance of the potentiometer used and which pickups we've paired them with. To counteract this roll-off, I can install an optional treble bleed circuit. It allows high frequencies to pass through even when you turn down the volume. Some players love treble bleed circuits, but many don't.  The treble roll-off at lower volumes is part of their playing style. They can make their guitar seem to have more low-end by rolling off the volume a bit (like around 8 on the dial). Then they can push to 10 when they want more drive and high end. This especially works great with high-gain amps or overdrive guitar pedals.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Reach Out. Let's Talk!",
                size: "tiny",
                icon: "chat",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/guitar-electronics-tone-capacitor-resistors.jpg" alt="Guitar Electronics Tone Capacitor and Resistors" />
          </div>
        }
        textRight={
          <div>
            <h3>Tone Capacitor Selection</h3>
            <p>
              Capacitors are components that store energy. But in the case of guitars, they are generally used to remove high frequencies from the signal. The lower the value of the capacitor, the higher up in the frequency spectrum the roll-off will start. A higher value tone capacitor will roll off further down the frequency spectrum. So, changes will be more audible and extreme. Pairing capacitors with different pickup types create different outcomes. It also matters the pickup's position on the guitar - neck or bridge. A neck pickup will sound darker than one in the bridge position. A PAF humbucker in the neck position can be very dark as compared to a telecaster pickup in the bridge. I can use different tone pots with different capacitor values to match the two pickups.
            </p>
            <h3>Resistors</h3>
            <p>
              Sometimes we need to use a resistor to match pickups with a circuit's design. Resistors come into play when combining single-coil and humbucker pickups in the same circuit. But I can also use resistors to change tonal relationships in a circuit. Or, I can change the sound of the same volume pot in different switch positions.
            </p>
            
          </div>
        }
      />
      <PageWrapper color="grey">
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/guitar-electronics-switch-5-poisiton-tele.jpg" alt="Guitar Electronics - Switches - Tele" />
            </div>
          }
          textRight={
            <div>
              <h3>Pickup Selectors</h3>
              <p>
                The pickup selector allows the guitarist to choose between different pickups - or a combination of pickups.
              </p>
              <p>
                T-style guitars will commonly use a 3-way switch. But even then, there are modifications. We can reverse the control plate so that the switch is at the bottom. Some players like this because it keeps the switch out of the way when they are strumming. Other T-style players need fast access to that switch and want it positioned on top.
              </p>
              <p>
                But there are also popular 4-position T-style mods that provide a range of pickup options. I often use these on my Taurus model when using T-style pickups.
              </p>
              <p>
                3-position toggle switches are common on LP style 2-pickup guitars. They allow you to choose the neck pickup, bridge pickup, or both. Often these guitars will have independent volume and tone controls for each pickup. This gives you a wide range of settings and a quick way to switch between them.
              </p>

            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <p>
                An S-Style guitar with three single coils often uses a 5-way switch. The 2 and 4 switch positions choose a combination of two pickups in parallel at the same time. You can select pickups where the middle pickup is "reverse-wound, reverse-polarity". This makes the 2 and 4 positions "silent" like a humbucker. It also gives the distinctive "quack" sound S-style players often like. But, you can also get these wound standard, which was standard on the original strats. This removes the humbucking qualities but also bypasses the high-end roll-off.
              </p>
            </div>
          }
          textRight={
            <div>                
              <img src="/images/guitar-electronics-switch-5-poisiton.jpg" alt="Guitar Electronics 5-Position Strat Switch" />
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <img src="/images/guitar-electronics-switch-3-poisiton.jpg" alt="Guitar Electronics 3-Position Toggle Switch" />
            </div>
          }
          textRight={
            <div>
              <p>
                3-position toggle switches are common on LP style 2-pickup guitars. They allow you to choose the neck pickup, bridge pickup, or both. Often these guitars will have independent volume and tone controls for each pickup. This gives you a wide range of settings and a quick way to switch between them.
              </p>
            </div>
          }
        />
        <TextBlock
          textPadded
          textLeft={
            <div>
              <p>
                Slider switches are often found on many classic offset guitars from the 60's. These can be two or three-position switches and provide an array of options:
              </p>
              <ul>
                <li>basic on/of for each pickup</li>
                <li>engaging tonal circuits like high or low pass filtering</li>
              </ul>
              <p>
                These can be difficult to switch while playing depending on their placement. But they can also be less likely to get bumped accidentally.
              </p>
            </div>
          }
          textRight={
            <div>                
              <img src="/images/guitar-electronics-switch-slider.jpg" alt="Guitar Electronics Slider Switch" />
            </div>
          }
        />
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h3>1950 Wiring versus Modern Wiring</h3>
            <p>
              How we wire your custom guitar also affects its sound. With any two-pickup guitar, there is the choice of "1950s wiring" or "modern wiring". This is a difference in how I wire the tone capacitor between the volume and tone pots.
            </p>
            <h4>1950 Wiring</h4>
            <p>
              Many musicians who often use the volume knob to clean up the sound when playing overdriven tones. They decide to apply this mod to their guitars so they don't lose highs as they clean up their tone. 1950s wiring maintains high frequencies at all volume settings. But, turning the tone knob can affect volume, which some players find frustrating.
            </p>
            <h4>Modern Wiring</h4>
            <p>
              Modern wiring eliminates the problem of the tone pot affecting the volume.  But, in exchange, your lose some high frequencies when you turn the volume down. Some players like this, some don't. You can install a "treble bleed circuit" (mentioned above) to counteract this. But it can aggressively favor the high frequencies as compared to the 50's wiring. 
            </p>
            <p>
              The choice often comes down to how much you plan to use your tone knob.
            </p>
            <h4>Other Wiring Options</h4>
            <p>
              Some players want a direct connection to the pickup. A volume knob and nothing else. I can do that.
            </p>
            <p>
              Others like more experimental circuits. For, instance, for S-style 3-pickup configurations, you can use a "<a href="https://emersoncustom.com/products/s5b-blender-5-way-strat-prewired-kit" target="_blank" rel="noreferrer">blender circuit</a>". This wires the first tone knob to all the pickups. But to bottom tone knob blends between the neck and bridge pickups when in the first and fifth positions of the pickup selector. It's a pretty cool and useable mod.
            </p>
            <p>
              The circuits in offset guitars from the 60's and 70's can get pretty complex. Rhythm circuits, tone shaping, etc. If I can get a schematic, I can do it.
            </p>
            <p>
              Anything is possible. Let me know what you want to try out and we'll figure it out.
            </p>
          </div>
        }
      />
    <div className="section textured">
      <TextBlock
        textPadded
        textLeft={
          <div>
            <img src="/images/guitar-electronics-output-jack.jpg" alt="Guitar Electronics Output Jack" />
          </div>
        }
        textRight={
          <div>
            <h2>Output Jack</h2>
            <p>
              This may seem simple enough, but the type of output jack used on the guitar can be important. The jacks themselves are the same, but how I mount them to the guitar can vary depending on the guitar body and player's preference. Often, I will place jacks on the side of my guitars to get them out of the player's way. But even then, there are many different ways of mounting them. I'll help you select the best hardware for your design, both aesthetically and functionally.
            </p>
            <p>
              There is no single "silver bullet" when it comes to guitar tone. Guitar signals produce a very weak electrical signal that gets amplified significantly. So, changes to all the components above will have some effect. Ultimately, it's the entire circuit - from pickup to output jack - that will dictate how your guitar sounds. Not to mention the <a href="/guitar-body/">guitar body</a>, <a href="/custom-guitar-necks/">neck</a>, and <a href="/guitar-bridges/">bridge</a>. 
            </p>
            <p>
              We'll discuss all the guitar electronics options available when we design your dream guitar. If you have specific things you want to try, that's great. If not, I will make suggestions based on what I think will work best for what you are trying to achieve.
            </p>
          </div>
        }
      />
    </div>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Get in touch. Let's talk about building your dream guitar!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default GuitarElectronics
